@import "src/themes/daikiri/styles/index.scss";
.qr-editor {
  &__banner {
    h1 {
      margin-bottom: 8px !important;
      text-align: center;
    }
    h2 {
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;

      @include sm {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  &__container {
    width: 100%;
    max-width: 1156px;
    margin-top: space(1.5);
    border-radius: 8px;
    box-shadow: 5px 0px 8px 0px #a6a6a640;
    background-color: white;
    padding: 1px;
  }
}
